var mdlcActions = (function () {
	'use strict'

	var sliderGallery = function () {
		if (!document.querySelector('.post .gallery')) return

		var slider = tns({
			container: '.post .gallery',
			autoplay: false,
			nav: true,
			items: 1,
			gutter: 5,
			slideBy: 1,
			loop: false,
			responsive: {
				480: {
					items: 2,
					slideBy: 2,
				},
				576: {
					items: 3,
					slideBy: 3
				},
				768: {
					items: 4,
					slideBy: 4
				}
			},
			autoplayButtonOutput: false,
			controlsText: ['<span class="sr-only">Précédent</span>', '<span class="sr-only">Suivant</span>']
		})
	}

	return {
		sliderGallery: sliderGallery
	}
})()

mdlcActions.sliderGallery()