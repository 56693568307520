var mdlcPortail = (function () {
	'use strict'

	var sliderHomePage = function () {
		if (!document.querySelector('.portail-hp-slider')) return

		var slider = tns({
			container: '.portail-hp-slider',
			autoplay: true,
			autoplayHoverPause: true,
			autoplayTimeout: 5000,
			nav: false,
			autoplayButtonOutput: false,
			controlsText: ['<span class="sr-only">Précédent</span>', '<span class="sr-only">Suivant</span>']
		})
	}

	return {
		sliderHomePage: sliderHomePage
	}
})()

mdlcPortail.sliderHomePage()