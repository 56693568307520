var maisonDeLaChimie = (function () {
	'use strict'

	var siteSwitch = function () {
		var select = document.querySelector('.select-site')

		if (!select) {
			return
		}

		select.addEventListener('change', function () {
			var selectValue = this.value

			if (selectValue === '') {
				return
			}

			window.location = selectValue
		})
	}

	var _expandingTextarea = function (event) {
		var element = event.target
		element.style.height = '24px'
		element.style.height = element.scrollHeight + 'px'
	}

	var form = function () {
		if (!document.querySelector('.modern-form')) {
			return
		}

		new ModernForm('.modern-form', {
			inputSelector: '.form-input',
			classHasFocus: 'has-focus',
			classHasContent: 'has-content'
		})

		var textareas = [].slice.call(document.querySelectorAll('.expand-textarea'))

		textareas.forEach(function (element) {
			element.addEventListener('change', _expandingTextarea, false)
			element.addEventListener('cut', _expandingTextarea, false)
			element.addEventListener('paste', _expandingTextarea, false)
			element.addEventListener('keyup', _expandingTextarea, false)
			element.addEventListener('keydown', _expandingTextarea, false)
		})
	}

	var mobileMenu = function () {
		var menu = document.querySelector('.main-menu')
		var button = document.querySelector('.menu_opener')

		if (!menu || !button) {
			return
		}

		if (typeof window.innerWidth != 'undefined' && window.innerWidth < 992) {
			button.addEventListener('click', function (event) {
				event.preventDefault()
				menu.classList.toggle('is-open')
			})

			var menuItems = [].slice.call(menu.querySelectorAll('.menu-item-has-children'))

			menuItems.forEach(function (item) {
				item.firstChild.addEventListener('click', function (event) {
					event.preventDefault()
					item.querySelector('.sub-menu').classList.toggle('is-open')
				})
			})
		}
	}

	return {
		siteSwitch: siteSwitch,
		mobileMenu: mobileMenu,
		form: form
	}
})()

maisonDeLaChimie.mobileMenu()
maisonDeLaChimie.siteSwitch()
maisonDeLaChimie.form()